import React, { FC } from 'react'

import {
  // Wrapper,
  // TopButton,
  topSpacingValues
} from './styled'

const ScrollToTopContainer: FC<{
  noContainer?: boolean
  topSpacing?: keyof typeof topSpacingValues
}> = ({ noContainer, topSpacing = 'normal', children }) => {
  // const handleTopClick = () => {
  //   document.body.scrollIntoView({
  //     behavior: 'smooth',
  //   })
  // }

  return (
    <div>
      {children}

      {/* <Wrapper noContainer={noContainer} topSpacing={topSpacing}>
        <TopButton inverted onClick={handleTopClick}>
          Top
        </TopButton>
      </Wrapper> */}
    </div>
  )
}

export default ScrollToTopContainer
