import React, { useState, ReactNode, FC } from 'react'

import Button from 'components/button'
import { LoadMoreContainer, NoContentMessage } from './styled'

type Props = {
  pageContext: {
    slug: string
    pagePosts: readonly BlogPost[]
    currentPage: number
    countPages: number
  }
  renderItem: (value: BlogPost) => ReactNode
  WrapperComponent: FC
  collection: string
  noContentMessage: string
}

const LoadMoreBlog = ({
  pageContext,
  renderItem,
  WrapperComponent,
  collection,
  noContentMessage,
}: Props) => {
  const [posts, setPosts] = useState(pageContext.pagePosts)
  const [page, setPage] = useState(pageContext.currentPage)
  const [isLoading, setIsLoading] = useState(false)

  const handleLoadMore = async () => {
    setIsLoading(true)

    try {
      const response = await fetch(
        `${__PATH_PREFIX__}/pagination-json/${collection}/${
          pageContext.slug
        }/${page + 1}.json`
      )
      const parsedResponse = await response.json()
      setPosts(prevPosts => [...prevPosts, ...parsedResponse])
      setPage(prevPage => prevPage + 1)
    } catch (err) {
      // TODO - handle the error
      //console.log(err)
    }

    setIsLoading(false)
  }

  return (
    <>
      {posts.length ? (
        <WrapperComponent>{posts.map(renderItem)}</WrapperComponent>
      ) : (
        <NoContentMessage>{noContentMessage}</NoContentMessage>
      )}

      {page < pageContext.countPages && (
        <LoadMoreContainer>
          <Button disabled={isLoading} onClick={handleLoadMore}>
            {isLoading ? 'Loading posts…' : 'Load More'}
          </Button>
        </LoadMoreContainer>
      )}
    </>
  )
}

export default LoadMoreBlog
