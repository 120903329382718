import styled from 'styled-components'
import mq from 'styles/mq'

export const Container = styled.section`
  max-width: 108rem;
  margin: 0 auto;
  padding: 6rem 3rem 0;

  ${mq.medium} {
    padding: 13rem 3rem 0;
  }

  ${mq.large} {
    padding: 13rem 0 0;
  }
`

export const Collection = styled.div`
  padding-bottom: 3.2rem;

  ${mq.medium} {
    padding-bottom: 7.2rem;
  }
`
