import { Link } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'
import { heading } from 'styles/typography'
import theme from 'styles/theme'
import mq from 'styles/mq'

export const Wrapper = styled.article`
  align-items: center;
  margin-bottom: 7.2rem;

  ${mq.medium} {
    display: flex;
    margin-bottom: 8rem;
  }
`

export const ThumbnailWrapper = styled(Link)`
  position: relative;
  display: block;

  &:before {
    content: '';
    display: block;
    padding-top: 67.3%;
  }

  ${mq.medium} {
    width: 35rem;
  }

  ${mq.desktop} {
    width: 53rem;
  }
`

export const Content = styled.div`
  padding-top: 1.6rem;

  ${mq.medium} {
    flex: 1;
    padding-left: 5rem;
  }

  ${mq.desktop} {
    padding-left: 9rem;
  }
`

export const Thumbnail = styled(Img)`
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${theme.color.background.gray};
`

export const Category = styled.div`
  ${heading.xxs};
  margin-bottom: 1.2rem;
`

export const Title = styled(Link)`
  ${heading.l};
  margin-bottom: 1.2rem;
`
