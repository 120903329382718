import styled from 'styled-components'
import Img from 'gatsby-image'
import theme from 'styles/theme'
import mq from 'styles/mq'
import { heading, paragraph } from 'styles/typography'

export const Wrapper = styled.section`
  background: ${theme.color.background.light};
  padding: 13rem 4rem 11rem;
  text-align: center;

  ${mq.medium} {
    padding: 13rem 0 15rem;
  }
`

export const Name = styled.h1`
  ${heading.xl};

  ${mq.medium} {
    margin-bottom: 1.2rem;
  }
`

export const Details = styled.p`
  ${paragraph.normal};
  max-width: 44rem;
  margin: 0 auto;
`

export const Avatar = styled(Img)`
  width: 8rem;
  height: 8rem;
  border-radius: 50%;
  margin: 0 auto 2.8rem;
`
