import React from 'react'
import { Wrapper, Name, Details, Avatar } from './styled'

type Props = {
  author: Author
}

const AuthorInfo = ({ author }: Props) => {
  return (
    <Wrapper>
      {/* @ts-expect-error */}
      <Avatar fluid={(author.thumbnail as FluidImage).childImageSharp.fluid} />
      <Name>{author.fullName}</Name>
      <Details dangerouslySetInnerHTML={{ __html: author.details }}></Details>
    </Wrapper>
  )
}

export default AuthorInfo
