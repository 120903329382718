import styled from 'styled-components'

import mq from 'styles/mq'
import { paragraph } from 'styles/typography'

export const LoadMoreContainer = styled.div`
  text-align: center;
  margin: -3rem auto 8rem;

  ${mq.medium} {
    margin: -5rem auto 13rem;
  }

  button {
    ${mq.large} {
      padding-left: 8em;
      padding-right: 8em;
    }
  }
`

export const NoContentMessage = styled.p`
  ${paragraph.large}
  text-align: center;
  margin: 8rem 0;

  ${mq.medium} {
    margin: 13rem 0;
  }
`
