import styled from 'styled-components'

import theme from 'styles/theme'
import mq from 'styles/mq'
import { heading } from 'styles/typography'

export const Wrapper = styled.nav`
  display: none;
  background: ${theme.color.background.light};
  border-bottom: 0.1rem solid ${theme.color.border.light};

  ${mq.medium} {
    display: block;
    position: sticky;
    top: 7rem;
    z-index: 500;
  }
`

export const Items = styled.ul`
  margin: 0 auto;
  max-width: fit-content;
`

export const Item = styled.li<{ isActive: boolean }>`
  padding: 2rem 0;
  display: inline-block;
  ${heading.xs};
  font-size: 1.4rem;
  color: ${p =>
    p.isActive ? theme.color.accent.primary : theme.color.text.dark};

  &:not(:last-child) {
    margin-right: 2.4rem;
  }
`
