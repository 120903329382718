import React from 'react'
import { Link } from 'gatsby'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import urls, { blogCategoryUrl } from 'common/urls'
import { Wrapper, Items, Item } from './styled'

type Props = {
  categories: readonly BlogCategory[]
  current?: string
}

const Categories = ({ current, categories }: Props) => {
  return (
    <Wrapper>
      <Items>
        {/* <Item isActive={urls.sperm101 === current}>
          <Link to={urls.sperm101}>Sperm 101</Link>
        </Item> */}
        {categories.map(node => (
          <Item key={node.id} isActive={node.slug === current}>
            <Link to={blogCategoryUrl(node.slug)}>{node.title}</Link>
          </Item>
        ))}
      </Items>
    </Wrapper>
  )
}

export default Categories
