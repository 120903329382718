import React from 'react'
import {
  Wrapper,
  ThumbnailWrapper,
  Thumbnail,
  Content,
  Title,
  Category,
} from './styled'

type Props = {
  post: BlogPost
}

const BlogPost = ({ post }: Props) => {
  return (
    <Wrapper>
      <ThumbnailWrapper
        to={post.fields.slug}
        aria-label={post.frontmatter.title}
      >
        {/* @ts-expect-error */}
        <Thumbnail fluid={post.frontmatter.thumbnail.childImageSharp.fluid} />
      </ThumbnailWrapper>
      <Content>
        <Category>{post.frontmatter.category.title}</Category>
        <Title to={post.fields.slug}>{post.frontmatter.title}</Title>
        <p>{post.excerpt}</p>
      </Content>
    </Wrapper>
  )
}
export default BlogPost
