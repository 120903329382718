import React from 'react'
import { graphql } from 'gatsby'
import Layout from 'components/layout'
import Categories from 'components/categories'
import SEO from 'components/seo'
import LoadMoreBlog from 'components/load-more-blog'
import ScrollToTopContainer from 'components/scroll-to-top'
import AuthorInfo from './author-info'
import BlogPost from './blog-post'
import { Container, Collection } from './styled'

type Props = {
  data: {
    author: Author
    allCategoriesJson: {
      nodes: readonly BlogCategory[]
    }
  }
  pageContext: {
    slug: string
    pagePosts: readonly BlogPost[]
    currentPage: number
    countPages: number
  }
}

const BlogAuthor = ({ data, pageContext }: Props) => {
  const categories = data.allCategoriesJson.nodes

  return (
    <Layout withHeaderBorder>
      <SEO title={`Posts by ${data.author.fullName}`} />
      <Categories categories={categories} current={pageContext.slug} />
      <AuthorInfo author={data.author} />
      <ScrollToTopContainer>
        <Container>
          <LoadMoreBlog
            pageContext={pageContext}
            renderItem={node => <BlogPost post={node} key={node.fields.slug} />}
            WrapperComponent={Collection}
            collection="by-author"
            noContentMessage="There are no posts from this author yet."
          />
        </Container>
      </ScrollToTopContainer>
    </Layout>
  )
}

export default BlogAuthor

export const pageQuery = graphql`
  query BlogPostsByAuthorSlug($slug: String!) {
    author: authorsJson(slug: { eq: $slug }) {
      ...authorFields
    }
    allCategoriesJson {
      nodes {
        ...categoryFields
      }
    }
  }
`
